import axios from 'axios'

const apiServer = process.env.REACT_APP_API_SERVER

export const saveP2PPolicy = (token, params) => {
    return axios.post(`${apiServer}/admin/p2p-policy`, params, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchActiveP2PPolicy = (token) => {
    return axios.get(`${apiServer}/admin/p2p-policy`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchActiveP2PPolicy_Net = (token, net) => {
    return axios.get(`${apiServer}/admin/p2p-policy-net/${net}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

