import axios from "axios"

export const fetchTradeFeePolicy = () => {
    return new Promise((res, rej) => {
        axios.get(`${process.env.REACT_APP_API_SERVER}/public/tradefee`)
        .then(result => {
            res(result.data)
        })
        .catch(rej)
    })
}

export const saveTradeFeePolicy = (token, brokerAccount, amount) => {
    return new Promise((res, rej) => {
        axios.post(`${process.env.REACT_APP_API_SERVER}/admin/tradefee`, {
            brokerAccount,
            amount,
        }, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        })
        .then(result => {
            res(result.data)
        })
        .catch(rej)
    })
}
