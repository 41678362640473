import {useNavigate} from 'react-router-dom'
import { useCarousels } from '../../atoms/carousel'
import './CarouselList.css'
import { getDateStr } from '../../helpers/common'

export const CarouselList = () => {
    const [carousels, ] = useCarousels()
    const navigate = useNavigate()

    const onClickNew = () => {
        navigate('/carousel/new')
    }

    const onClickEdit = (item) => {
        navigate(`/carousel/${item._id}`)
    }
    
    return (
        <div className='carousel-list-page-wrapper'>
            <div className='carousel-list-page'>
                <h2>슬라이드 목록</h2>

                <div className='action-row'>
                    <div className='btn-wrapper'>
                        <button onClick={onClickNew}>
                            슬라이드 추가
                        </button>
                    </div>
                </div>

                <table className='carousel-list'>
                    <thead>
                        <tr>
                            <th>제목</th>
                            <th>생성일</th>
                            <th>공개여부</th>
                            <th>상세</th>
                        </tr>
                    </thead>
                    <tbody>
                        {carousels.map((item, i) =>
                            <tr key={i} className='carousel-list-item'
                                style={item.isPublic ? {} : {backgroundColor: '#ddd'}}>
                                <td className='name'>
                                    {item.subject.en}<br />
                                    {item.subject.kr}
                                </td>
                                <td className='date'>
                                    {getDateStr(item.created_at)}
                                </td>
                                <td>
                                    {item.isPublic ? '공개' : '비공개'}
                                </td>
                                <td className='btn-wrapper'>
                                    <button onClick={() => onClickEdit(item)}>
                                        상세
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
