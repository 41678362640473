import { useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAdminEmail, useAdminToken, useAdminTokenUpdate } from '../atoms/auth'
import { loadToken, removeLocalToken, saveToken } from '../utils/auth'
import './Template.css'
import { verifyToken } from '../queries/auth'

export const Template = () => {
    const token = useAdminToken()
    const setToken = useAdminTokenUpdate()
    const [_email, setEmail] = useAdminEmail()
    const navigate = useNavigate()
    const location = useLocation()
    
    useEffect(() => {
        if (!token) {
            const savedToken = loadToken()
            if (savedToken) {
                verifyToken(savedToken)
                .then(result => {
                    setToken(savedToken)
                    setEmail(result.email)
                })
                .catch(() => {
                    removeLocalToken()
                    setToken(null)
                    setEmail(null)
                    navigate('/signin')
                })
            } else {
                navigate('/signin')
            }
        } else {
            saveToken(token)
            navigate('/policies')
        }
    }, [token])

    const navMenu = [
        {route: '/brokers', text: '브로커 주소'},
        {route: '/p2p', text: 'P2P 송금'},
        {route: '/policies', text: '수수료'},
        {route: '/collections', text: '컬렉션 등록'},
        {route: '/collectionbundles', text: '컬렉션 소개'},
        {route: '/carousels', text: '광고 슬라이드'},
        {route: '/admins', text: '관리자 목록'},
        {route: '/marketplaces', text: '거래소 목록'},
        {route: '/memberstores', text: '가맹점 목록'},
        // {route: '/test-features', text: '기능 테스트'},
    ]
    
    return (
    <>
        <div className="app-wrapper">
            {token &&
            <nav>
                <div className='nav-title'>
                    DIG NFT ADMIN
                </div>
                <div className='nav-menu'>
                    {navMenu.map(({route, text}, i) =>
                        <div key={i} className='nav-menu-item'
                            style={location.pathname === route ? {textDecoration: 'underline'} : {}}
                        >
                            <Link to={route}>{text}</Link>
                        </div>
                    )}
                </div>
            </nav>
            }
            <div className="app-content">
                <Outlet />
            </div>
        </div>
    </>
    )
}
