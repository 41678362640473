import { useCollectionId, useCollectionMetadata } from "../../atoms/collection"
import { CollectionNftList } from "./CollectionNftList"

export const CollectionInfo = () => {
    const [account, taxon] = useCollectionId()
    const metadata = useCollectionMetadata()

    if (!account && !taxon && !metadata) {
        return null
    }

    return (
        <div className='collection-info'>
            <hr />
            {!metadata ?
                <div className='no-info'>
                    등록되지 않은 컬렉션입니다.
                </div>
            :
                <div className='info-exists'>
                    등록되어 있는 컬렉션입니다.
                </div>
            }
            <h2>컬렉션 정보</h2>
            <ul>
                <li>Account: {account}</li>
                <li>Taxon: {taxon}</li>
            </ul>
            <CollectionNftList />
        </div>
    )
}
