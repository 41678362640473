import axios from 'axios'

export const uploadImage = (token, image, name, type) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/image`, {
        image,
        name,
        type,
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}