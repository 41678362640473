
import axios from "axios"

export const fetchAdmins = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/admins`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const createAdmin = (token, email) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/admin`, {
        email
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const removeAdmin = (token, email) => {
    return axios.delete(`${process.env.REACT_APP_API_SERVER}/admin/admin`,
    {
        data: { email },
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}
