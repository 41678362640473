import axios from 'axios'

export const requestEmailAuth = (email) => {
    return new Promise((res, rej) => {
        axios.post(`${process.env.REACT_APP_API_SERVER}/public/signin_admin`, {
            email
        })
        .then(result => {
            if (result.data?.success === true) {
                res(result.data)
            } else {
                throw new Error("Failed to authenticate.")
            }
        })
        .catch(rej)
    })
}

export const signIn = (email, passcode) => {
    return new Promise((res, rej) => {
        axios.post(`${process.env.REACT_APP_API_SERVER}/public/signin_admin`, {
            email,
            passcode
        })
        .then(result => {
            if (result.data?.token) {
                res(result.data)
            } else {
                throw new Error("Failed to authenticate.")
            }
        })
        .catch(rej)
    })
}

export const verifyToken = (token) => {
    return new Promise((res, rej) => {
        axios.get(`${process.env.REACT_APP_API_SERVER}/admin/verify`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then(result => {
            if (result.data?.verified === true) {
                res(result.data)
            } else {
                throw new Error("Failed to authenticate.")
            }
        })
        .catch(rej)
    })
}
