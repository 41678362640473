import { useEffect, useState } from "react"
import { fetchMemberStoresOnHold, fetchMemberStoresVerified, fetchMemberStoresWaiting, fetchMerchantsOnHold, fetchMerchantsVerified, fetchMerchantsWaiting, holdRemoteMemberStore, holdRemoteMerchant, verifyRemoteMemberStore, verifyRemoteMerchant } from "../../queries/memberstore"
import { useAdminToken } from "../../atoms/auth"
import { MemberStoreList } from "./MemberStoreList"
import './styles.css'

export const MemberStores = () => {
    const [waitingList_Off, setWaitingList_Off] = useState([])
    const [verifiedList_Off, setVerifiedList_Off] = useState([])
    const [onHoldList_Off, setOnHoldList_Off] = useState([])
    const [waitingList_On, setWaitingList_On] = useState([])
    const [verifiedList_On, setVerifiedList_On] = useState([])
    const [onHoldList_On, setOnHoldList_On] = useState([])
    const token = useAdminToken()

    const loadWaitingList_Offline = async () => {
        try {
            let res = await fetchMemberStoresWaiting(token)
            setWaitingList_Off(res?.data?.stores || [])
        } catch (error) {
            console.error('error while fetching waiting list')
        }
    }

    const loadVerifiedList_Offline = async () => {
        try {
            let res = await fetchMemberStoresVerified(token)
            setVerifiedList_Off(res?.data?.stores || [])
        } catch (error) {
            console.error('error while fetching verified list')
        }
    }

    const loadOnHoldList_Offline = async () => {
        try {
            let res = await fetchMemberStoresOnHold(token)
            setOnHoldList_Off(res?.data?.stores || [])
        } catch (error) {
            console.error('error while fetching on-hold list')
        }
    }

    const loadWaitingList_Online = async () => {
        try {
            let res = await fetchMerchantsWaiting(token)
            setWaitingList_On(res?.data?.merchants || [])
        } catch (error) {
            console.error('error while fetching waiting list')
        }
    }

    const loadVerifiedList_Online = async () => {
        try {
            let res = await fetchMerchantsVerified(token)
            setVerifiedList_On(res?.data?.merchants || [])
        } catch (error) {
            console.error('error while fetching verified list')
        }
    }

    const loadOnHoldList_Online = async () => {
        try {
            let res = await fetchMerchantsOnHold(token)
            setOnHoldList_On(res?.data?.merchants || [])
        } catch (error) {
            console.error('error while fetching on-hold list')
        }
    }

    const loadLists = () => {
        loadWaitingList_Offline()
        loadVerifiedList_Offline()
        loadOnHoldList_Offline()

        loadWaitingList_Online()
        loadVerifiedList_Online()
        loadOnHoldList_Online()
    }

    useEffect(() => {
        if (!!token) {
            loadLists()
        }
    }, [token])

    const verifyItem_Offline = async (item) => {
        try {
            let res = await verifyRemoteMemberStore(token, item._id)
            if (res?.data?.success) {
                alert('승인 처리되었습니다.')
                loadLists()
            } else {
                alert('승인 처리 도중 오류가 발생했습니다.')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const verifyItem_Online = async (item) => {
        try {
            let res = await verifyRemoteMerchant(token, item._id)
            if (res?.data?.success) {
                alert('승인 처리되었습니다.')
                loadLists()
            } else {
                alert('승인 처리 도중 오류가 발생했습니다.')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const holdItem_Offline = async (item, comment) => {
        try {
            let res = await holdRemoteMemberStore(token, item._id, comment)
            if (res?.data?.success) {
                alert('보류 처리되었습니다.')
                loadLists()
            } else {
                alert('보류 처리 도중 오류가 발생했습니다.')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const holdItem_Online = async (item, comment) => {
        try {
            let res = await holdRemoteMerchant(token, item._id, comment)
            if (res?.data?.success) {
                alert('보류 처리되었습니다.')
                loadLists()
            } else {
                alert('보류 처리 도중 오류가 발생했습니다.')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const matchWithKeyword_Offline = (searchKeyword, item) => {
        if (!searchKeyword) return true

        return item.name.indexOf(searchKeyword) >= 0 ||
                item.owner.email.indexOf(searchKeyword) >= 0 ||
                item.region.indexOf(searchKeyword) >= 0 ||
                item.subRegion.indexOf(searchKeyword) >= 0 ||
                item.address.indexOf(searchKeyword) >= 0 ||
                item.phone.indexOf(searchKeyword) >= 0 ||
                item.homepage.indexOf(searchKeyword) >= 0 ||
                item.memo.indexOf(searchKeyword) >= 0
    }

    const matchWithKeyword_Online = (searchKeyword, item) => {
        if (!searchKeyword) return true

        return item.name.indexOf(searchKeyword) >= 0 ||
                item.address.indexOf(searchKeyword) >= 0 ||
                item.phone.indexOf(searchKeyword) >= 0 ||
                item.website.indexOf(searchKeyword) >= 0
    }
    
    return (
        <div className='memberstores'>
            <h2>오프라인</h2>
            <MemberStoreList items={waitingList_Off} title='승인 대기중'
                onClickVerify={verifyItem_Offline}
                onClickHold={holdItem_Offline}
                matchWithKeyword={matchWithKeyword_Offline}
            />
            <MemberStoreList items={verifiedList_Off} title='승인 완료'
                onClickVerify={() => {}}
                onClickHold={holdItem_Offline}
                disableVerify={true}
                matchWithKeyword={matchWithKeyword_Offline}
            />
            <MemberStoreList items={onHoldList_Off} title='보류'
                onClickVerify={verifyItem_Offline}
                onClickHold={() => {}}
                disableHold={true}
                matchWithKeyword={matchWithKeyword_Offline}
            />
            <hr />
            <h2>온라인</h2>
            <MemberStoreList items={waitingList_On} title='승인 대기중'
                onClickVerify={verifyItem_Online}
                onClickHold={holdItem_Online}
                matchWithKeyword={matchWithKeyword_Online}
            />
            <MemberStoreList items={verifiedList_On} title='승인 완료'
                onClickVerify={() => {}}
                onClickHold={holdItem_Online}
                disableVerify={true}
                matchWithKeyword={matchWithKeyword_Online}
            />
            <MemberStoreList items={onHoldList_On} title='보류'
                onClickVerify={verifyItem_Online}
                onClickHold={() => {}}
                disableHold={true}
                matchWithKeyword={matchWithKeyword_Online}
            />
        </div>
    )
}
