import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { Template } from './template/Template';
import { SignIn } from './routes/auth/SignIn';
import { PolicySettings } from './routes/policies/PolicySettings';
import { CollectionSettings } from './routes/collection/CollectionSettings';
import { BrokerSettings } from './routes/brokers/BrokerSettings';
import { AdminSettings } from './routes/admins/AdminSettings';
import { TestFeatures } from './routes/test-features/TestFeatures';
import { MarketplacesSettings } from './routes/marketplaces/MarketplacesSettings';
import { CarouselSettings } from './routes/carousel/CarouselSettings';
import { CarouselList } from './routes/carousel/CarouselList';
import { CollectionBundles } from './routes/collection/CollectionBundles';
import { CollectionBundleSettings } from './routes/collection/CollectionBundleSettings';
import { P2PSettings } from './routes/p2p/P2PSettings';
import { MemberStores } from './routes/memberstores/MemberStores';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Template />,
    children: [
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/policies",
        element: <PolicySettings />
      },
      {
        path: "/p2p",
        element: <P2PSettings />
      },
      {
        path: "/brokers",
        element: <BrokerSettings />
      },
      {
        path: "/collectionbundles",
        element: <CollectionBundles />
      },
      {
        path: "/collectionbundle/new",
        element: <CollectionBundleSettings />
      },
      {
        path: "/collectionbundle/edit/:id",
        element: <CollectionBundleSettings />
      },
      {
        path: "/collections",
        element: <CollectionSettings />
      },
      {
        path: "/carousels",
        element: <CarouselList />
      },
      {
        path: "/carousel/new",
        element: <CarouselSettings />
      },
      {
        path: "/carousel/:id",
        element: <CarouselSettings />
      },
      {
        path: "/admins",
        element: <AdminSettings />
      },
      {
        path: "/marketplaces",
        element: <MarketplacesSettings />
      },
      {
        path: "/memberstores",
        element: <MemberStores />
      },
      {
        path: "/test-features",
        element: <TestFeatures />
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
