import axios from 'axios'

const apiServer = process.env.REACT_APP_API_SERVER

export const fetchMemberStoresWaiting = (token) => {
    return axios.get(`${apiServer}/admin/memberstore/applications/waiting`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchMemberStoresVerified = (token) => {
    return axios.get(`${apiServer}/admin/memberstore/applications/verified`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchMemberStoresOnHold = (token) => {
    return axios.get(`${apiServer}/admin/memberstore/applications/on-hold`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchMerchantsWaiting = (token) => {
    return axios.get(`${apiServer}/admin/pg/merchants/waiting`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchMerchantsVerified = (token) => {
    return axios.get(`${apiServer}/admin/pg/merchants/verified`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchMerchantsOnHold = (token) => {
    return axios.get(`${apiServer}/admin/pg/merchants/on-hold`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const verifyRemoteMemberStore = (token, id) => {
    return axios.put(`${apiServer}/admin/memberstore/verify/${id}`, {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const holdRemoteMemberStore = (token, id, comment) => {
    return axios.put(`${apiServer}/admin/memberstore/hold/${id}`, {comment}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const verifyRemoteMerchant = (token, id) => {
    return axios.put(`${apiServer}/admin/pg/merchant/verify/${id}`, {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const holdRemoteMerchant = (token, id, comment) => {
    return axios.put(`${apiServer}/admin/pg/merchant/hold/${id}`, {comment}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}
