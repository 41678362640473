import { useEffect, useState } from "react"
import { useAdminToken } from "../../atoms/auth"
import { createAdmin, fetchAdmins, removeAdmin } from "../../queries/admin"
import './AdminSettings.css'

export const AdminSettings = () => {
    const token = useAdminToken()
    const [admins, setAdmins] = useState([])
    const [emailInput, setEmailInput] = useState('')

    useEffect(() => {
        updateAdminList()
    }, [])

    const updateAdminList = () => {
        fetchAdmins(token)
            .then(res => setAdmins(res?.data || []))
            .catch(e => {
                console.error(e)
                alert('관리자 정보 로드 중 에러가 발생했습니다.')
            })
    }

    const onClickRemove = (email) => {
        if (window?.confirm(`${email}\n위 주소의 관리자 권한을 제거하시겠습니까?`)) {
            removeAdmin(token, email)
                .then(() => {
                    alert('관리자 권한을 제거했습니다.')
                    updateAdminList()
                })
                .catch(e => {
                    console.error(e)
                    alert('관리자 삭제 도중 에러가 발생했습니다.')
                })
        }
    }

    const onClickAddAdmin = () => {
        createAdmin(token, emailInput)
            .then(() => {
                updateAdminList()
                setEmailInput('')
                alert('관리자를 추가했습니다.')
            })
            .catch(e => {
                console.error(e)
                alert('관리자 추가 도중 에러가 발생했습니다.')
            })
    }
    
    return (
        <div className='policy-settings-wrapper'>
            <div className='policy-settings-box'>
                <h3>관리자 설정</h3>
                <div className='setting-row'>
                    <div className='head'>새 관리자 메일주소</div>
                    <input type='email' value={emailInput}
                        onChange={e => setEmailInput(e.target.value)}
                        placeholder="dig@nft.com"
                    />
                </div>
                <div className='button-row'>
                    <button disabled={!emailInput}
                        onClick={onClickAddAdmin}>관리자 추가</button>
                </div>

                <div className='admin-list'>
                    {admins.map(admin =>
                        <div className='admin-list-item'>
                            <div className='admin-email'>
                                {admin.email}
                                <div className='grey'>
                                    {!!admin.activity_at ?
                                        `최근 활동: ${new Date(admin.activity_at).toLocaleDateString('ko')}`
                                        : '활동 없음'}
                                </div>
                            </div>
                            <div className='remove-admin-btn-wrapper'>
                                <button onClick={() => onClickRemove(admin.email)}>
                                    제거
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}