import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { adminTokenAtom } from '../../atoms/auth'
import { fetchTradeFeePolicy, saveTradeFeePolicy } from '../../queries/policy'
import './PolicySettings.css'

export const PolicySettings = () => {
    const [token, _] = useAtom(adminTokenAtom)
    const [feeAmount, setFeeAmount] = useState('')
    const [brokerAccount, setBrokerAccount] = useState('')
    const [isWaiting, setIsWaiting] = useState(false)
    const [error, setError] = useState(null)
    
    useEffect(() => {
        fetchTradeFeePolicy()
            .then(tf => {
                if (tf?._id) {
                    setBrokerAccount(tf.brokerAccount)
                    setFeeAmount(tf.amount)
                } else {
                    setError('수수료 설정을 불러올 수 없습니다.')
                }
            })
            .catch((res) => {
                if (res.response?.status == 404) {
                    setError('수수료 설정을 불러올 수 없습니다.')
                } else {
                    console.error(res)
                    setError('서버 오류')
                }
            })
    }, [])

    const onClickApply = () => {
        setError('')
        setIsWaiting(true)
        saveTradeFeePolicy(token, brokerAccount, feeAmount)
            .then(tf => {
                setIsWaiting(false)
                if (tf._id) {
                    setBrokerAccount(tf.brokerAccount)
                    setFeeAmount(tf.amount)
                    alert('수수료 정책을 성공적으로 적용했습니다.')
                }
            })
            .catch(() => {
                setIsWaiting(false)
                setError('서버 오류')
            })
    }

    const isApplyButtonDisabled = () => {
        return !brokerAccount || !feeAmount || isWaiting
    }
    
    return (
        <div className='policy-settings-wrapper'>
            <div className='policy-settings-box'>
                <h3>수수료 정책 설정</h3>
                {error &&
                    <div className='error-box'>
                        {error}
                    </div>
                }
                <div className='setting-row fee'>
                    <div className='head'>수수료 수취 지갑주소(브로커 지갑주소)</div>
                   <div className='input-wrapper'>
                        <input type='text' placeholder='지갑주소'
                            value={brokerAccount}
                            onChange={e => setBrokerAccount(e.target.value)}
                        />
                   </div>
                </div>
                <div className='setting-row broker'>
                   <div className='head'>수수료율(0.001%)</div>
                   <div className='input-wrapper'>
                        <input type='number' placeholder='1 = 0.001%'
                            value={feeAmount}
                            onChange={e => setFeeAmount(e.target.value)}
                        />
                   </div>
                </div>
                <div className='button-row'>
                    <button className='btn-save' onClick={onClickApply}
                        disabled={isApplyButtonDisabled()}
                    >
                        적용
                    </button>
                </div>
            </div>
        </div>
    )
}
