import axios from "axios"

export const fetchBrokers = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/brokers`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const updateBroker = (token, account, key) => {
    return axios.put(`${process.env.REACT_APP_API_SERVER}/admin/broker`,{
        account,
        privateKey: key,
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}
