import { useState, useEffect } from 'react'
import { useAdminToken } from '../../atoms/auth'
import { fetchBrokers, updateBroker } from '../../queries/broker'
import './BrokerSettings.css'

export const BrokerSettings = () => {
    const token = useAdminToken()
    const [brokers, setBrokers] = useState([])
    const [isWaiting, setIsWaiting] = useState(false)
    const [error, setError] = useState(null)
    const [accountInput, setAccountInput] = useState('')
    const [keyInput, setKeyInput] = useState('')
    
    const refresh = () => {
        fetchBrokers(token)
            .then(res => {
                if (res?.data?.length >= 0) {
                    setError('')
                    setBrokers(res.data)
                } else {
                    setError('브로커 계정을 불러올 수 없습니다.')
                }
            })
            .catch((res) => {
                if (res.response?.status == 403) {
                    setError('수수료 설정을 불러올 수 없습니다.')
                } else {
                    console.error(res)
                    setError('서버 오류')
                }
            })
    }
    
    useEffect(() => {
        refresh()
    }, [])

    const onClickSave = () => {
        setError('')
        setIsWaiting(true)
        updateBroker(token, accountInput, keyInput)
            .then(res => {
                setIsWaiting(false)
                refresh()
                alert('브로커 계정을 저장했습니다.')
            })
            .catch(err => {
                setIsWaiting(false)
                setError(err?.message || err)
            })
    }

    const isSaveButtonDisabled = () => {
        return isWaiting || !accountInput || !keyInput
    }
    
    return (
        <div className='policy-settings-wrapper'>
            <div className='policy-settings-box' style={{backgroundColor: '#dedede'}}>
                {error &&
                    <div className='error-box'>
                        {error}
                    </div>
                }
                <h3>브로커 계정 설정</h3>
                <p>이미 등록된 지갑주소로 저장 시 개인키를 덮어씁니다.</p>
                <div className='setting-row fee'>
                    <div className='head'>브로커 지갑주소</div>
                   <div className='input-wrapper'>
                        <input type='text' placeholder='r...'
                            value={accountInput}
                            onChange={e => setAccountInput(e.target.value)}
                        />
                   </div>
                </div>
                <div className='setting-row broker'>
                   <div className='head'>브로커 패밀리 시드</div>
                   <div className='input-wrapper'>
                        <input type='text' placeholder='s...'
                            value={keyInput}
                            onChange={e => setKeyInput(e.target.value)}
                        />
                   </div>
                </div>
                <div className='button-row'>
                    <button className='btn-save' onClick={onClickSave}
                        disabled={isSaveButtonDisabled()}
                    >
                        저장
                    </button>
                </div>
                {brokers &&
                    <div className='broker-list'>
                        <h3>등록된 브로커 주소 목록</h3>
                        {brokers.map(e =>
                            <div key={e.account} className='broker-account'>
                                {e.account}
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}
