import { useAdminToken } from '../../atoms/auth'
import { createCarouselContent, fetchCarouselContent, updateCarouselContent } from '../../queries/carousel'
import { uploadImage } from '../../queries/common'
import './CarouselSettings.css'
import {useState, useRef, useEffect} from 'react'
import { useParams } from "react-router-dom"
import {Link} from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const CarouselSettings = () => {
    const pageParams = useParams()
    const [imgSrc, setImgSrc] = useState('')
    const [link, setLink] = useState('')
    const [ctaLink, setCtaLink] = useState('')
    const [ctaTextEn, setCtaTextEn] = useState('')
    const [subjectEn, setSubjectEn] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [ctaTextKr, setCtaTextKr] = useState('')
    const [subjectKr, setSubjectKr] = useState('')
    const [descriptionKr, setDescriptionKr] = useState('')
    const [isPublic, setIsPublic] = useState(false)
    const imgInputRef = useRef()
    const token = useAdminToken()

    const initInputs = () => {
        setImgSrc('')
        setLink('')
        setCtaLink('')
        setCtaTextEn('')
        setCtaTextKr('')
        setSubjectEn('')
        setSubjectKr('')
        setDescriptionEn('')
        setDescriptionKr('')
        setIsPublic(false)
    }

    const fetchContent = () => {
        if (!pageParams?.id) return

        fetchCarouselContent(token, pageParams.id)
        .then(res => {
            if (res?.data?.carouselContent) {
                const content = res.data.carouselContent
                setImgSrc(content.imgSrc)
                setLink(content.link)
                setCtaLink(content.ctaLink)
                setCtaTextEn(content.ctaText?.en)
                setCtaTextKr(content.ctaText?.kr)
                setSubjectEn(content.subject?.en)
                setSubjectKr(content.subject?.kr)
                setDescriptionEn(content.description?.en)
                setDescriptionKr(content.description?.kr)
                setIsPublic(content.isPublic)
            }
        })
    }

    useEffect(() => {
        if (pageParams?.id) {
            fetchContent()
        }
    }, [pageParams])

    const onClickUploadImg = () => {
        imgInputRef.current.click()
    }

    const onChangeImgInput = async (event) => {
        const file = event.target.files[0];
        const convertedFile = await convertFileToBase64(file);
        
        const res = await uploadImage(token, convertedFile, file.name, file.type)
        if (res.data?.url) {
            setImgSrc(res.data.url)
        }
    }

    const onClickSaveBtn = () => {
        let params = {
            imgSrc,
            link,
            ctaLink,
            ctaText: {
                en: ctaTextEn,
                kr: ctaTextKr,
            },
            subject: {
                en: subjectEn,
                kr: subjectKr,
            },
            description: {
                en: descriptionEn,
                kr: descriptionKr,
            },
            isPublic,
        }

        if (pageParams?.id) {
            updateCarouselContent(token, pageParams.id, params)
            .then(() => {
                alert('슬라이드가 수정되었습니다.')
                fetchContent()
            })
        } else {
            createCarouselContent(token, params)
            .then(() => {
                alert('슬라이드가 등록되었습니다.')
                initInputs()
            })
        }
    }
    
    return (
        <div className='carousel-settings-wrapper'>
            <div className='carousel-settings'>
                <div className='title-row'>
                    <Link to='/carousels'>
                        <ArrowBackIosNewIcon />
                    </Link>
                    <h2>광고 슬라이드 {pageParams?.id ? '수정' : '추가'}</h2>
                </div>
                <div className='img-display'>
                    <h4>슬라이드 이미지</h4>
                    {imgSrc && <img src={imgSrc} />}
                </div>
                <div className='setting-row'>
                    <div className='btn-wrapper'>
                        <button onClick={onClickUploadImg}>
                            슬라이드 이미지 업로드
                        </button>
                    </div>
                    <input type='file' accept='image/*' hidden ref={imgInputRef}
                        onChange={onChangeImgInput}
                    />
                </div>
                <div className='setting-row'>
                    <div className='head'>이미지 링크</div>
                    <div className='input-wrapper'>
                        <input type='text' value={link} onChange={e => setLink(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>버튼 링크(버튼 생략시 공백)</div>
                    <div className='input-wrapper'>
                        <input type='text' value={ctaLink} onChange={e => setCtaLink(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>한글 버튼 텍스트</div>
                    <div className='input-wrapper'>
                        <input type='text' value={ctaTextKr} onChange={e => setCtaTextKr(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>영문 버튼 텍스트</div>
                    <div className='input-wrapper'>
                        <input type='text' value={ctaTextEn} onChange={e => setCtaTextEn(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>한글 제목</div>
                    <div className='input-wrapper'>
                        <input type='text' value={subjectKr} onChange={e => setSubjectKr(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>영문 제목</div>
                    <div className='input-wrapper'>
                        <input type='text' value={subjectEn} onChange={e => setSubjectEn(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>한글 설명</div>
                    <div className='input-wrapper'>
                        <textarea width={280} height={100} value={descriptionKr} onChange={e => setDescriptionKr(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>영문 설명</div>
                    <div className='input-wrapper'>
                        <textarea width={280} height={100} value={descriptionEn} onChange={e => setDescriptionEn(e.target.value)} />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>공개 여부</div>
                    <div className='input-wrapper'>
                        <input type='checkbox' checked={isPublic} onChange={e => setIsPublic(e.target.checked)} />
                    </div>
                </div>
                <div className='btn-row'>
                    <button onClick={onClickSaveBtn}>
                        등록하기
                    </button>
                </div>
            </div>
        </div>
    )
}

export const convertFileToBase64 = (file) => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        }
    })
}

