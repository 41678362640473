import axios from "axios"

export const fetchCollectionMetadata = (account, taxon) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/xrpl/nft_collection_metadata/${account}/${taxon}`)
}

export const fetchCollectionNfts = (account, taxon) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/xrpl/nft_collection/${account}/${taxon}`)
}

export const uploadCollectionImage = (token, image, name, type) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/image`, {
        image,
        name,
        type
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const createCollectionMetadata = (token, metadata) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/collection`, metadata, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const updateCollectionMetadata = (token, id, metadata) => {
    return axios.put(`${process.env.REACT_APP_API_SERVER}/admin/collection/${id}`, metadata, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const updateCollectionSnapshots = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/task/update-snapshots`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const updateVolumeTopCollections = (token, period) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/task/top-collections/${period}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchIncompleteCollections = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/collections/incomplete`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchCompleteCollections = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/collections/complete`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const updateCollectionRankings = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/task/rank-collections`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchMarketplaces = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/marketplaces`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const postNewMarketplace = (token, name) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/marketplace/new`, {
        name
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const postMarketplaceRankingEnable = (token, name) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/marketplace/rank/enable`, {
        name
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const postMarketplaceRankingDisable = (token, id) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/marketplace/rank/disable`, {
        id
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const removeMarketplace = (token, id) => {
    return axios.delete(`${process.env.REACT_APP_API_SERVER}/admin/marketplace/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchCollectionBundles = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/collectionbundles`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchCollectionBundle = (token, id) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/collectionbundle/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const createCollectionBundle = (token, params) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/collectionbundle`, params, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const editCollectionBundle = (token, id, params) => {
    return axios.put(`${process.env.REACT_APP_API_SERVER}/admin/collectionbundle/${id}`, params, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const removeCollectionBundle = (token, id) => {
    return axios.delete(`${process.env.REACT_APP_API_SERVER}/admin/collectionbundle/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const cacheNftsInCollection = (token, issuer, taxon) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/cache-nfts/${issuer}/${taxon}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const tagNftsInCollection = (token, issuer, taxon) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/tag-nfts/${issuer}/${taxon}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const cacheAndTagNftsInCollection = (token, issuer, taxon) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/cache-and-tag-nfts/${issuer}/${taxon}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const cacheNftsInAllCollections = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/cache-nfts-all`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

