import './CollectionSettings.css'
import './LaunchpadSettings.css'

export const LaunchpadSettings = ({launchpad, setLaunchpad}) => {

    const onChange = (key, value) => {
        setLaunchpad({
            ...launchpad,
            [key]: value,
        })
    }
    
    return (
        <div className='lauchpad-settings'>
            <hr />
            <h2>런치패드 설정</h2>
            <div className='property-row'>
                <div className='head'>
                    현재 판매량
                </div>
                <div className='value'>
                    {launchpad?.soldNftIds?.length || 0}
                </div>
            </div>
            <div className='property-row'>
                <div className='head'>
                    최종 발행량
                </div>
                <div className='input-wrapper'>
                    <input type='text' value={launchpad?.totalSupply || ''}
                        onChange={(e) => onChange('totalSupply', e.target.value)}
                    />
                </div>
            </div>
            <div className='property-row'>
                <div className='head'>
                    스톡 부족시 메일알림
                </div>
                <div className='input-wrapper'>
                    <input type='checkbox' checked={launchpad?.alertStockShortage || false}
                        onChange={(e) => onChange('alertStockShortage', e.target.checked)}
                    />
                </div>
            </div>
            {launchpad?.alertStockShortage &&
            <>
            <div className='property-row'>
                <div className='head'>
                    기준 잔여스톡 수
                </div>
                <div className='input-wrapper'>
                <input type='number' value={launchpad?.stockShortageThreshold || ''}
                        onChange={(e) => onChange('stockShortageThreshold', e.target.value)}
                    />
                </div>
            </div>
            <div className='property-row'>
                <div className='head'>
                    알림 받을 메일
                </div>
                <div className='input-wrapper'>
                    <input type='text' value={launchpad?.mailsToAlert || ''}
                        placeholder='복수 입력시 ,로 구분(공백 미포함)'
                        onChange={(e) => onChange('mailsToAlert', e.target.value)}
                    />
                </div>
            </div>
            </>
            }
            <div className='property-row'>
                <div className='head'>
                    개당 가격(XRP)
                </div>
                <div className='input-wrapper'>
                    <input type='number' value={launchpad?.currentPrice || ''}
                        onChange={(e) => onChange('currentPrice', e.target.value)}
                    />
                </div>
            </div>
            {launchpad?.issuerAddress &&
            <div className='property-row'>
                <div className='head'>
                    발행자 주소
                </div>
                <div className='input-wrapper'>
                    <input type='text' value={launchpad.issuerAddress}
                        disabled
                    />
                </div>
            </div>
            }
            <div className='property-row'>
                <div className='head'>
                    발행자 시크릿
                </div>
                <div className='input-wrapper'>
                    <input type='text' value={launchpad?.issuerSecret || ''}
                        onChange={(e) => onChange('issuerSecret', e.target.value)}
                        placeholder={launchpad?.issuerAddress ? '위 주소의 시크릿이 등록되어 있음' : 'sEd...'}
                    />
                </div>
            </div>
            <div className='property-row'>
                <div className='head'>
                    런치패드 활성화
                </div>
                <div className='input-wrapper'>
                    <input type='checkbox' checked={launchpad?.isActive || false}
                        onChange={(e) => onChange('isActive', e.target.checked)}
                        disabled={(!launchpad?.issuerSecret && !launchpad?.issuerAddress) || !launchpad?.currentPrice}
                    />
                </div>
            </div>
            <hr />
        </div>
    )
}
