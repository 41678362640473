import './CollectionBundles.css'
import { useCollectionBundles } from '../../atoms/collection'
import { getDateStr } from '../../helpers/common'
import {useNavigate} from 'react-router-dom'

export const CollectionBundles = () => {
    const [bundles,] = useCollectionBundles()
    const navigate = useNavigate()
    
    const onClickCreate = () => {
        navigate('/collectionbundle/new')
    }

    const onClickEditItem = bundle => {
        navigate(`/collectionbundle/edit/${bundle._id}`)
    }
    
    return (
        <div className='collection-bundles-page-wrapper'>
            <div className='collection-bundles-page'>
                <h2>콜렉션 소개</h2>
                <div className='button-row'>
                    <button onClick={onClickCreate}>
                        추가하기
                    </button>
                </div>
                <table className='collection-bundle-list'>
                    <thead>
                        <tr>
                            <th>이름</th>
                            <th>콜렉션 수</th>
                            <th>생성일</th>
                            <th>수정</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bundles.map((el, i) =>
                            <tr key={i} className='collection-bundle-list-item'>
                                <td className='name'>
                                    {el.name}
                                </td>
                                <td className='collection-count'>
                                    {el.collections.length}
                                </td>
                                <td className='date'>
                                    {getDateStr(el.created_at)}
                                </td>
                                <td>
                                    <button onClick={() => onClickEditItem(el)}>
                                        수정
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
