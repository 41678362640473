import { useEffect, useState } from 'react'
import { useAdminToken } from '../../atoms/auth'
import '../policies/PolicySettings.css'
import { fetchActiveP2PPolicy, fetchActiveP2PPolicy_Net, saveP2PPolicy } from '../../queries/p2p'

export const P2PSettings = () => {
    const networkOptions = ['xrpl', 'solana', 'avalanche']

    const [feeAmount, setFeeAmount] = useState('')
    const [feeRecipient, setFeeRecipient] = useState('')
    const [network, setNetwork] = useState(networkOptions[0])
    const [timeLimitInSeconds, setTimeLimitInSeconds] = useState('')
    const [isWaiting, setIsWaiting] = useState(false)
    const [error, setError] = useState(null)
    const token = useAdminToken()

    const getRemotePolicy = () => {
        fetchActiveP2PPolicy_Net(token, network)
            .then(res => {
                if (res.data?.policy?._id) {
                    setFeeRecipient(res.data.policy.feeRecipient)
                    setFeeAmount(res.data.policy.fee)
                    setTimeLimitInSeconds(res.data.policy.timeLimitInSeconds || 600)
                } else {
                    setError('P2P 설정을 불러올 수 없습니다.')
                }
            })
            .catch((res) => {
                if (res.response?.status == 404) {
                    setError('P2P 설정을 불러올 수 없습니다.')
                } else {
                    console.error(res)
                    setError('서버 오류')
                }
            })
    }
    
    useEffect(() => {
        getRemotePolicy()
    }, [network])
    
    const onClickApply = () => {
        setError('')
        setIsWaiting(true)
        const params = {
            feeRecipient,
            fee: feeAmount,
            network,
            timeLimitInSeconds,
        }
        saveP2PPolicy(token, params)
            .then(res => {
                setIsWaiting(false)
                if (res.data?.policy?._id) {
                    setFeeRecipient(res.data.policy.feeRecipient)
                    setFeeAmount(res.data.policy.fee)
                    setTimeLimitInSeconds(res.data.policy.timeLimitInSeconds)
                    alert('P2P 설정을 성공적으로 적용했습니다.')
                }
            })
            .catch(() => {
                setIsWaiting(false)
                setError('서버 오류')
            })
    }

    const isApplyButtonDisabled = () => {
        return !feeRecipient || !feeAmount || isWaiting
    }
    
    return (
        <div className='policy-settings-wrapper'>
            <div className='policy-settings-box'>
                <h3>P2P 송금 설정</h3>
                {error &&
                    <div className='error-box'>
                        {error}
                    </div>
                }
                <div className='setting-row network'>
                    <div className='head'>네트워크</div>
                    <div className='input-wrapper'>
                        <select onChange={e => setNetwork(e.target.value)}>
                            {networkOptions.map(el =>
                                <option key={el} value={el}>{el.toUpperCase()}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className='setting-row fee'>
                    <div className='head'>수수료 수취 지갑주소</div>
                    <div className='input-wrapper'>
                        <input type='text' placeholder='지갑주소'
                            value={feeRecipient}
                            onChange={e => setFeeRecipient(e.target.value)}
                        />
                    </div>
                </div>
                <div className='setting-row broker'>
                    <div className='head'>수수료율(0.001%)</div>
                    <div className='input-wrapper'>
                        <input type='number' placeholder='1 = 0.001%'
                            value={feeAmount}
                            onChange={e => setFeeAmount(e.target.value)}
                        />
                    </div>
                </div>
                <div className='setting-row broker'>
                    <div className='head'>결제 제한시간(초)</div>
                    <div className='input-wrapper'>
                        <input type='number'
                            value={timeLimitInSeconds}
                            onChange={e => setTimeLimitInSeconds(e.target.value)}
                        />
                    </div>
                </div>
                <div className='button-row'>
                    <button className='btn-save' onClick={onClickApply}
                        disabled={isApplyButtonDisabled()}
                    >
                        적용
                    </button>
                </div>
            </div>
        </div>
    )
}
