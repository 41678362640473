import {useEffect} from 'react'
import {atom, useAtom} from 'jotai'
import { fetchCollectionMetadata, fetchCollectionNfts, fetchMarketplaces, fetchIncompleteCollections, fetchCollectionBundles, fetchCompleteCollections } from '../queries/collection'
import { useAdminToken } from './auth'

export const collectionAccountAtom = atom('')
export const collectionTaxonAtom = atom(null)

export const collectionMetadataAtom = atom(null)
export const colelctionNftsAtom = atom([])

export const collectionSearchAccountInputAtom = atom('')
export const collectionSearchTaxonInputAtom = atom('')

export const useCollectionSearchAccountInput = () => useAtom(collectionSearchAccountInputAtom)
export const useCollectionSearchTaxonInput = () => useAtom(collectionSearchTaxonInputAtom)

export const useCollectionId = () => {
    const [account, setAccount] = useAtom(collectionAccountAtom)
    const [taxon, setTaxon] = useAtom(collectionTaxonAtom)

    return [account, taxon]
}

export const useCollectionIdUpdate = () => {
    const [account, setAccount] = useAtom(collectionAccountAtom)
    const [taxon, setTaxon] = useAtom(collectionTaxonAtom)

    return [setAccount, setTaxon]
}

export const useCollectionMetadata = () => {
    const [metadata, _setMeatadata] = useAtom(collectionMetadataAtom)

    return metadata
}

export const useRemoteCollectionMetadata = () => {
    const [_metadata, setMetadata] = useAtom(collectionMetadataAtom)
    const [account, taxon] = useCollectionId()

    const update = () => {
        fetchCollectionMetadata(account, taxon)
            .then(result => {
                if (result.data?._id) {
                    setMetadata(result.data)
                } else {
                    setMetadata(null)
                }
            })
            .catch(() => {
                setMetadata(null)
            })
    }

    return update
}

export const useCollectionNfts = () => {
    const [nfts, _setNfts] = useAtom(colelctionNftsAtom)

    return nfts
}

export const useRemoteCollectionNfts = () => {
    const [_nfts, setNfts] = useAtom(colelctionNftsAtom)
    const [account, taxon] = useCollectionId()

    const update = () => {
        fetchCollectionNfts(account, taxon)
            .then(result => {
                if (result.data?.nfts) {
                    setNfts(result.data.nfts)
                }
            })
            .catch(() => {
                setNfts([])
            })
    }

    return update
}

const incompCollsAtom = atom([])
const compCollsAtom = atom([])

export const useIncompleteCollections = () => {
    const token = useAdminToken()
    const [incompColls, setIncompColls] = useAtom(incompCollsAtom)

    const update = () => {
        fetchIncompleteCollections(token)
        .then(res => {
            setIncompColls(res?.data?.collections || [])
        })
    }

    return [incompColls, update]
}

export const useCompleteCollections = () => {
    const token = useAdminToken()
    const [compColls, setCompColls] = useAtom(compCollsAtom)

    const update = () => {
        fetchCompleteCollections(token)
        .then(res => {
            setCompColls(res?.data?.collections || [])
        })
    }

    return [compColls, update]
}

const marketplacesAtom = atom([])

export const useMarketplaces = () => {
    const token = useAdminToken()
    const [items, setItems] = useAtom(marketplacesAtom)

    const update = () => {
        fetchMarketplaces(token)
        .then(res => {
            setItems(res?.data?.marketplaces || [])
        })
    }

    useEffect(() => {
        update()
    }, [])

    return [items, update]
}

const collectionBundles = atom([])

export const useCollectionBundles = () => {
    const token = useAdminToken()
    const [bundles, setBundles] = useAtom(collectionBundles)

    const refresh = () => {
        fetchCollectionBundles(token)
        .then(res => {
            setBundles(res?.data?.collectionBundles)
        })
    }

    useEffect(() => {
        refresh()
    }, [])

    return [bundles, refresh]
}
