import './TestFeatures.css'

import {useState} from 'react'
import { useAdminToken } from "../../atoms/auth"
import { cacheAndTagNftsInCollection, cacheNftsInAllCollections, cacheNftsInCollection, tagNftsInCollection, updateCollectionRankings, updateCollectionSnapshots, updateVolumeTopCollections } from "../../queries/collection"

export const TestFeatures = () => {
    const token = useAdminToken()
    const [accountInput, setAccountInput] =useState('')
    const [taxonInput, setTaxonInput] =useState('')

    const onClickUpdateSnapshots = () => {
        updateCollectionSnapshots(token)
        .then((res) => {
            if (res?.data?.success === true) {
                alert('success')
            }
        })
    }

    const onClickUpdateTopSnapshots = (period) => {
        updateVolumeTopCollections(token, period)
        .then((res) => {
            if (res?.data?.success === true) {
                alert('success')
            }
        })
    }

    const onClickUpdateRankings = () => {
        updateCollectionRankings(token)
    }

    const onClickCacheAndTagNfts = () => {
        cacheAndTagNftsInCollection(token, accountInput, taxonInput)
        .then((res) => {
            if (res?.data?.success === true) {
                alert('success')
            }
        })
    }

    const onClickCacheAllNfts = () => {
        cacheNftsInAllCollections(token)
        .then((res) => {
            if (res?.data?.success === true) {
                alert('success')
            }
        })
    }

    return (
        <div className='test-features'>
            <button onClick={() => onClickUpdateTopSnapshots('all')}>
                Top 콜렉션 목록 업데이트(전체)
            </button>
            <button onClick={() => onClickUpdateTopSnapshots('week')}>
                Top 콜렉션 목록 업데이트(1주)
            </button>
            <button onClick={onClickUpdateSnapshots}>
                콜렉션 스냅샷 업데이트
            </button>
            <button onClick={onClickUpdateRankings}>
                콜렉션 랭킹 업데이트
            </button>

            <br />
            <br />
            <div className='input-row'>
                <div className='head'>Issuer</div>
                <div className='input-row'>
                    <input type='text' value={accountInput}
                        onChange={e => setAccountInput(e.target.value)}
                    />
                </div>
            </div>
            <div className='input-row'>
                <div className='head'>Taxon</div>
                <div className='input-row'>
                    <input type='text' value={taxonInput}
                        onChange={e => setTaxonInput(e.target.value)}
                    />
                </div>
            </div>
            <button onClick={onClickCacheAndTagNfts}>
                NFT 썸네일화 & 레어리티 산정
            </button>
            <hr />
            <button onClick={onClickCacheAllNfts}>
                모든 콜렉션 NFT 썸네일화 & 레어리티 산정
            </button>
        </div>
    )
}
 