import './MarketplacesSettings.css'
import { RankingsSettings } from './RankingsSettings'

export const MarketplacesSettings = () => {
    return (
        <div className='marketplaces-settings'>
            <h2>거래소 목록 관리</h2>
            <RankingsSettings />
        </div>
    )
}
