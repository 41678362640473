import './CollectionBundleSettings.css'
import {useState, useEffect} from 'react'
import { useParams } from "react-router-dom"
import { createCollectionBundle, editCollectionBundle, fetchCollectionBundle, removeCollectionBundle } from '../../queries/collection'
import { useAdminToken } from '../../atoms/auth'
import { searchCollections } from '../../queries/search'
import { getShortenedHashString } from '../../helpers/common'
import {Link, useNavigate} from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const CollectionBundleSettings = () => {
    const pageParams = useParams()
    const [name, setName] = useState('')
    const [isPublic, setIsPublic] = useState(true)
    const [collections, setCollections] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const token = useAdminToken()
    const navigate = useNavigate()

    useEffect(() => {
        if (pageParams?.id) {
            fetchItem()
        }
    }, [])

    const fetchItem = () => {
        fetchCollectionBundle(token , pageParams.id)
        .then(res => {
            const bundle = res?.data?.collectionBundle
            if (bundle) {
                setName(bundle.name)
                setCollections(bundle.collections)
                setIsPublic(bundle.isPublic)
            }
        })
    }
    
    const onClickSearchBtn = () => {
        if (searchInput) {
            searchCollections(searchInput)
            .then(res => {
                if (res?.data?.collections) {
                    setSearchResults(res.data.collections)
                }
            })
        }
    }

    const onClickSearchResult = (coll) => {
        setCollections([...collections, coll])
    }

    const onClickCollectionListItem = (coll) => {
        let idx = collections.findIndex(el => el._id === coll._id)
        let newCollections = [...collections.slice(0, idx), ...collections.slice(idx+1)]
        setCollections(newCollections)
    }

    const onClickSaveBtn = () => {
        if (pageParams?.id) {
            editCollectionBundle(token, pageParams.id, {
                name,
                collections: collections.map(coll => coll._id),
                isPublic
            })
            .then(res => {
                alert('컬렉션 소개가 수정되었습니다.')
                fetchItem()
            })
        } else {
            createCollectionBundle(token, {
                name,
                collections: collections.map(coll => coll._id),
                isPublic
            })
            .then(res => {
                alert('컬렉션 소개가 추가되었습니다')
                setName('')
                setCollections([])
                setIsPublic(true)
                setSearchInput('')
                setSearchResults([])
            })
        }
    }

    const onClickRemove = () => {
        removeCollectionBundle(token, pageParams.id)
        .then(res => {
            alert('컬렉션 소개가 삭제되었습니다.')
            navigate('/collectionbundles')
        })
    }
    
    return (
        <div className='collection-bundle-settings-page-wrapper'>
            <div className='collection-bundle-settings-page'>
                <div className='title-row'>
                    <Link to='/collectionbundles'><ArrowBackIosNewIcon /></Link>
                    <h2>{pageParams?.id ? '콜렉션 소개 수정' : '콜렉션 소개 생성'}</h2>
                </div>
                {pageParams.id &&
                    <div className='delete-btn-row'>
                        <button onClick={onClickRemove}>삭제</button>
                    </div>
                }
                <div className='setting-row'>
                    <div className='head'>이름</div>
                    <div className='input-wrapper'>
                        <input type='text' value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className='setting-row'>
                    <div className='head'>공개 여부</div>
                    <div className='input-wrapper'>
                        <input type='checkbox' checked={isPublic}
                            onChange={e => setIsPublic(e.target.checked)}
                        />
                    </div>
                </div>
                <div className='collection-list'>
                    <h4>포함된 콜렉션({collections.length})</h4>
                    {collections.map((coll, i) =>
                        <div className='collection-list-item'>
                            <div className='img-wrapper'>
                                <img src={coll.profileImg} />
                            </div>
                            <div className='name'>{coll.name}</div>
                            <div className='account-info'>{getShortenedHashString(coll.account)}/{coll.taxon}</div>
                            <div className='action-row'>
                                <button onClick={() => onClickCollectionListItem(coll)}>
                                    소개에서 제거
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className='search-section'>
                    <h4>콜렉션 검색 및 추가</h4>
                    <div className='search-input-row'>
                        <div className='input-wrapper'>
                            <input type='text' placeholder='콜렉션 이름, 발행인 주소'
                                value={searchInput} onChange={e => setSearchInput(e.target.value)}
                            />
                        </div>
                        <div className='btn-wrapper'>
                            <button onClick={onClickSearchBtn}>검색</button>
                        </div>
                    </div>
                    {searchResults.length > 0 &&
                        <div className='search-results'>
                            {searchResults.map((el, i) =>
                                <div key={i} className='collection-list-item search-result-item'>
                                    <div className='img-wrapper'>
                                        {el.profileImg &&
                                            <img src={el.profileImg} />
                                        }
                                    </div>
                                    <div className='name'>
                                        {el.name}
                                    </div>
                                    <div className='account-info'>
                                        {getShortenedHashString(el.account)}/{el.taxon}
                                    </div>
                                    <div className='action-row'>
                                        {collections.findIndex(coll => coll._id === el._id) === -1 ?
                                            <button onClick={() => onClickSearchResult(el)}>
                                                소개에 추가
                                            </button>
                                            :
                                            '소개에 포함됨'
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div className='button-row'>
                    <button onClick={onClickSaveBtn}>
                        저장하기
                    </button>
                </div>
            </div>
        </div>
    )
}
