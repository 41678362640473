import { useEffect, useState } from "react"
import { useAdminToken } from "../../atoms/auth"
import { useCollectionId, useCollectionMetadata, useIncompleteCollections, useMarketplaces, useRemoteCollectionMetadata, useRemoteCollectionNfts } from "../../atoms/collection"
import { createCollectionMetadata, updateCollectionMetadata, uploadCollectionImage } from "../../queries/collection"
import { CollectionInfo } from "./CollectionInfo"
import { CollectionSearch } from "./CollectionSearch"
import './CollectionSettings.css'
import { IncompleteCollectionList } from "./IncompleteCollectionList"
import { CompleteCollectionList } from "./CompleteCollectionList"
import { LaunchpadSettings } from "./LaunchpadSettings"

export const CollectionSettings = () => {
    const [nameInput, setNameInput] = useState('')
    const [descInput, setDescInput] = useState('')
    const [profileS3Url, setProfileS3Url] = useState('')
    const [bannerS3Url, setBannerS3Url] = useState('')
    const [marketplaceInputs, setMarketplaceInputs] = useState([])
    const [snsLinksInput, setSnsLinksInput] = useState([])
    const [isAdminAuthed, setIsAdminAuthed] = useState(false)
    const [isHidingRarity, setIsHidingRarity] = useState(false)
    const [isInLastestSales, setIsInLastestSales] = useState(false)
    const token = useAdminToken()
    const metadata = useCollectionMetadata()
    const [account, taxon] = useCollectionId()
    const [, refreshIncompCollList] = useIncompleteCollections()
    const [marketplaces, ] = useMarketplaces()
    const [isShowingLaunchpadSettings, setIsShowingLaunchpadSettings] = useState(false)
    const [launchpad, setLaunchpad] = useState(null)

    const marketOptionList = marketplaces.map(m => m.name)

    const fetchMetadata = useRemoteCollectionMetadata()
    const fetchNfts = useRemoteCollectionNfts()

    const initInputs = () => {
        setNameInput('')
        setDescInput('')
        setSnsLinksInput([])
        setMarketplaceInputs([])
        setProfileS3Url('')
        setBannerS3Url('')
        setIsAdminAuthed(false)
    }

    useEffect(() => {
        initInputs()
    }, [])

    useEffect(() => {
        if (account && taxon) {
            fetchMetadata()
            fetchNfts()
        }
    }, [account, taxon])

    useEffect(() => {
        if (metadata) {
            setNameInput(metadata.name || '')
            setDescInput(metadata.description || '')
            setSnsLinksInput(metadata.snsLinks || [])
            setMarketplaceInputs(metadata.marketplaces || [])
            setProfileS3Url(metadata.profileImg || '')
            setBannerS3Url(metadata.bannerImg || '')
            setIsAdminAuthed(metadata.isAdminAuthed || false)
            setIsInLastestSales(metadata.isInLastestSales || false)
            setIsShowingLaunchpadSettings(typeof metadata?.launchpad === 'object')
            setLaunchpad(metadata?.launchpad || null)
        } else {
            initInputs()
        }
    }, [metadata])

    const onSelectFile = async (event, setUrl) => {
        const file = event.target.files[0];
        const convertedFile = await convertToBase64(file);
        
        const res = await uploadCollectionImage(token, convertedFile, file.name, file.type)
        console.log(res)
        if (res.data?.url) {
            setUrl(res.data.url)
        }
    }

    const convertToBase64 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
    }

    const onClickAddSnsLink = () => {
        setSnsLinksInput([...snsLinksInput, ''])
    }

    const onChangeSnsLinkInput = (value, index) => {
        const newLinks = [...snsLinksInput]
        newLinks[index] = value
        setSnsLinksInput(newLinks)
    }
    
    const onClickRemoveSnsLink = index => {
        const newLinks = [...snsLinksInput]
        newLinks.splice(index, 1)
        setSnsLinksInput(newLinks)
    }

    const onClickAddMarketplace = () => {
        setMarketplaceInputs([...marketplaceInputs, ''])
    }

    const onChangeMarketplace = (value, index) => {
        const newMarketplaces = [...marketplaceInputs]
        newMarketplaces[index] = value
        setMarketplaceInputs(newMarketplaces)
    }
    
    const onClickRemoveMarketplace = index => {
        const newMarketplaces = [...marketplaceInputs]
        newMarketplaces.splice(index, 1)
        setMarketplaceInputs(newMarketplaces)
    }

    const onClickSave = () => {
        if (!!window && window.confirm('컬렉션 정보를 등록하시겠습니까?')) {
            if (metadata) {
                const newMetadata = {
                    isAdminAuthed,
                    isHidingRarity,
                    isInLastestSales,
                    name: nameInput,
                    description: descInput,
                    profileImg: profileS3Url,
                    bannerImg: bannerS3Url,
                    marketplaces: marketplaceInputs.filter(mk => !!mk),
                    snsLinks: snsLinksInput.filter(link => !!link),
                    launchpad,
                }
                updateCollectionMetadata(token, metadata._id, newMetadata)
                .then(() => {
                    fetchMetadata()
                    refreshIncompCollList()
                    alert('컬렉션 정보를 등록했습니다.')
                })
                .catch(() => {
                    alert('저장에 실패했습니다.')
                })
            } else {
                const newMetadata = {
                    isAdminAuthed,
                    isHidingRarity,
                    isInLastestSales,
                    account,
                    taxon,
                    name: nameInput,
                    description: descInput,
                    profileImg: profileS3Url,
                    bannerImg: bannerS3Url,
                    marketplaces: marketplaceInputs.filter(mk => !!mk),
                    snsLinks: snsLinksInput.filter(link => !!link),
                    launchpad,
                }
                createCollectionMetadata(token, newMetadata)
                .then(() => {
                    fetchMetadata()
                    refreshIncompCollList()
                    alert('컬렉션 정보를 등록했습니다.')
                })
                .catch(() => {
                    alert('저장에 실패했습니다.')
                })
            }
        }
    }

    const isSaveBtnDisabled = () => !nameInput

    return (
        <div className='collection-settings-wrapper'>
            <div className='collections-lists-wrapper'>
                <IncompleteCollectionList />
                <CompleteCollectionList />
            </div>
            <div className='collection-settings-box'>

                <CollectionSearch />
                
                <CollectionInfo />

                {(account && taxon) &&

                <div className='collection-info-edit'>
                    <hr />
                    <div className="property-row">
                        <div className="head">
                            런치패드 설정
                        </div>
                        <div className="input-wrapper">
                            <input type='checkbox' checked={isShowingLaunchpadSettings} onChange={e => setIsShowingLaunchpadSettings(e.target.checked)} />
                        </div>
                    </div>
                    {isShowingLaunchpadSettings &&
                        <LaunchpadSettings launchpad={launchpad} setLaunchpad={setLaunchpad}  />
                    }
                    <h2>관리자 입력 정보</h2>
                    <div className="property-row">
                        <div className='head'>
                            DIG 인증
                        </div>
                        <div className='input-wrapper'>
                            <input type='checkbox' checked={isAdminAuthed} onChange={e => setIsAdminAuthed(e.target.checked)} />
                        </div>
                    </div>
                    <div className="property-row">
                        <div className='head'>
                            Rarity 숨기기
                        </div>
                        <div className='input-wrapper'>
                            <input type='checkbox' checked={isHidingRarity} onChange={e => setIsHidingRarity(e.target.checked)} />
                        </div>
                    </div>
                    <div className="property-row">
                        <div className='head'>
                            최근 거래 슬라이드에 포함
                        </div>
                        <div className='input-wrapper'>
                            <input type='checkbox' checked={isInLastestSales} onChange={e => setIsInLastestSales(e.target.checked)} />
                        </div>
                    </div>
                    <div className="property-row">
                        <div className='head'>
                            이름
                        </div>
                        <div className='input-wrapper'>
                            <input type='text' value={nameInput} onChange={e => setNameInput(e.target.value)} />
                        </div>
                    </div>
                    <div className="property-row">
                        <div className='head'>
                            소개문
                        </div>
                        <div className='input-wrapper'>
                            <textarea type='text' value={descInput} onChange={e => setDescInput(e.target.value)} />
                        </div>
                    </div>
                    <div className='img-showcase'>
                        <div className='head'>
                            배너 & 프로필 데모
                        </div>
                        {bannerS3Url &&
                            <div className='banner-img-wrapper'>
                                <img src={bannerS3Url} />
                            </div>
                        }
                        {profileS3Url &&
                            <div className='profile-img-wrapper'>
                                <img src={profileS3Url} />
                            </div>
                        }
                    </div>
                    <div className='property-row'>
                        <div className='head'>프로필 이미지</div>
                        <div className='file-input-wrapper'>
                            <input type="file" accept="image/*" onChange={e => onSelectFile(e, setProfileS3Url)}/>
                        </div>
                    </div>

                    <div className='property-row'>
                        <div className='head'>지원 거래소</div>
                        <div>
                            <div className='plus-btn-wrapper'>
                                <button className='plus-btn' onClick={onClickAddMarketplace}>
                                    지원 거래소 추가
                                </button>
                            </div>
                            <div className='input-list'>
                                {marketplaceInputs.map((mk, i) =>
                                    <div key={i} className='marketplace-input-row'>
                                        <div className='marketplace-input-wrapper'>
                                            <select value={mk} onChange={e => onChangeMarketplace(e.target.value, i)}>
                                                <option value={''} selected={marketplaceInputs[i] === ''}>
                                                    선택
                                                </option>
                                                {marketOptionList.map((mp, j) =>
                                                    <option key={j} value={mp} selected={marketplaceInputs[i] === mp}>
                                                        {mp}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        <div className='x-btn' onClick={() => onClickRemoveMarketplace(i)}>삭제</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    
                    <div className='property-row'>
                        <div className='head'>배너 이미지</div>
                        <div className='file-input-wrapper'>
                            <input type="file" accept="image/*" onChange={e => onSelectFile(e, setBannerS3Url)}/>
                        </div>
                    </div>

                    <div className='property-row'>
                        <div className='head'>SNS 링크</div>
                        <div className='sns-link-settings'>
                            <div className='plus-btn-wrapper'>
                                <button className='plus-btn' onClick={onClickAddSnsLink}>
                                    SNS 링크 추가 +
                                </button>
                            </div>
                            <div className='input-list'>
                                <div className='guide'>http:// 또는 https://를 앞에 꼭 붙여주세요.</div>
                                {snsLinksInput.map((snsLink, i) =>
                                    <div key={i} className='sns-link-input-row'>
                                        <div className='sns-link-input-wrapper'>
                                            <input type='text' value={snsLink} placeholder='https://instagram.com/...'
                                                onChange={e => onChangeSnsLinkInput(e.target.value, i)} />
                                        </div>
                                        <div className='x-btn' onClick={() => onClickRemoveSnsLink(i)}>삭제</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className='save-btn-wrapper'>
                        <button onClick={onClickSave} disabled={isSaveBtnDisabled()}>컬렉션 등록</button>
                    </div>
                </div>

                }

            </div>
        </div>
    )
}
