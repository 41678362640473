import {useEffect} from 'react'
import {atom, useAtom} from 'jotai'
import { fetchCarouselContents } from '../queries/carousel'
import { useAdminToken } from './auth'

const carouselsAtom = atom([])

export const useCarousels = () => {
    const [carousels, setCarousels] = useAtom(carouselsAtom)
    const token = useAdminToken()

    const fetchItems = () => {
        fetchCarouselContents(token)
        .then(res => {
            setCarousels(res?.data?.carouselContents || [])
        })
    }

    useEffect(() => {
        fetchItems()
    }, [])

    return [carousels, fetchItems]
}
