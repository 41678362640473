import { useEffect, useState } from 'react'
import { useCollectionSearchAccountInput, useCollectionSearchTaxonInput, useCompleteCollections } from '../../atoms/collection'
import './CompleteCollectionList.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const CompleteCollectionList = () => {
    const [showList, setShowList] = useState(false)
    const [, setAccountInput] = useCollectionSearchAccountInput()
    const [, setTaxonInput] = useCollectionSearchTaxonInput()
    const [compColls, refreshCollList] = useCompleteCollections()
    
    useEffect(() => {
        refreshCollList()
    }, [])

    const onClickCellBtn = (coll) => {
        setAccountInput(coll.account)
        setTaxonInput(coll.taxon)
    }
    
    return (
        <div className='complete-collection-list'>
            <h3 className='header' onClick={() => setShowList(!showList)}>입력 완료된 컬렉션 목록 ({compColls.length})
                {showList ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</h3>
            {showList &&
            <div className='table-wrapper'>
            <table>
                <thead>
                    <tr>
                        <th>발행인</th>
                        <th>Taxon</th>
                        <th>이름</th>
                        <th>거래소</th>
                        <th>검색</th>
                    </tr>
                </thead>
                <tbody>
                    {compColls.map((coll, i) =>
                        <tr key={i}>
                            <td>{coll.account.slice(0, 4) + '...'}</td>
                            <td>{coll.taxon}</td>
                            <td>{coll.name}</td>
                            <td>{(coll.marketplaces || []).join(', ')}</td>
                            <td>
                                <button onClick={() => onClickCellBtn(coll)}>검색칸에 입력</button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            </div>
            }
        </div>
    )
}
