import { useEffect } from "react"
import { useCollectionId, useCollectionIdUpdate, useCollectionSearchAccountInput, useCollectionSearchTaxonInput } from "../../atoms/collection"

export const CollectionSearch = () => {
    const [accountInput, setAccountInput] = useCollectionSearchAccountInput()
    const [taxonInput, setTaxonInput] = useCollectionSearchTaxonInput()

    const [setAccount, setTaxon] = useCollectionIdUpdate()
    const [account, taxon] = useCollectionId()

    useEffect(() => {
        setAccountInput(account || '')
        setTaxonInput(taxon || '')
    }, [])

    const onClickSearchButton = () => {
        setAccount(accountInput)
        setTaxon(taxonInput)
    }
    const isSearchBtnDisabled = () => !accountInput || !(Number(taxonInput) >= 0)

    return (
        <div className='collection-search-wrapper'>
            <div className='collection-search-header'>
                <h2>컬렉션 검색</h2>
                <p>아래의 입력칸에 NFT 발행자 지갑주소, Taxon 값을 차례로 입력하고 검색 버튼을 누르세요.</p>
            </div>
            <div className='collection-search-inputs'>
                <div className='search-input-row'>
                    <div className='head'>
                        지갑주소
                    </div>
                    <input type='text' placeholder='Account'
                        value={accountInput}
                        onChange={e => setAccountInput(e.target.value)}
                    />
                </div>
                <div className='search-input-row'>
                    <div className='head'>
                        Taxon
                    </div>
                    <input type='number' placeholder='Taxon'
                        value={taxonInput}
                        onChange={e => setTaxonInput(e.target.value)}
                    />
                </div>
            </div>
            <div className='collection-search-btn-wrapper'>
                <button onClick={onClickSearchButton}
                    disabled={isSearchBtnDisabled()}
                >검색</button>
            </div>
        </div>
    )
}
