import {atom, useAtom} from 'jotai'

export const adminEmailAtom = atom(null)

export const adminTokenAtom = atom(null)

export const useAdminEmail = () => {
    const [email, setEmail] = useAtom(adminEmailAtom)

    return [email, setEmail]
}

export const useAdminToken = () => {
    const [token, setToken] = useAtom(adminTokenAtom)

    return token
}

export const useAdminTokenUpdate = () => {
    const [token, setToken] = useAtom(adminTokenAtom)

    return setToken
}
