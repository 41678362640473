const tokenKey = 'dig-nft-admin-token'

export const saveToken = (token) => {
    localStorage.setItem(tokenKey, token)
}

export const loadToken = () => {
    return localStorage.getItem(tokenKey)
}

export const removeLocalToken = () => {
    localStorage.removeItem(tokenKey)
}
