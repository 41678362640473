import {useState} from 'react'
import { useMarketplaces } from "../../atoms/collection"
import { postMarketplaceRankingDisable, postMarketplaceRankingEnable, postNewMarketplace, removeMarketplace } from '../../queries/collection'
import { useAdminToken } from '../../atoms/auth'

export const RankingsSettings = () => {
    const [exchangeInput, setExchangeInput] = useState('')
    const [marketplaces, refreshMarketplaces] = useMarketplaces()
    const token = useAdminToken()

    const onClickAddExchange = () => {
        if (marketplaces.find(ex => ex.name === exchangeInput)) {
            alert('이미 등록된 거래소입니다')
            return
        }
        postNewMarketplace(token, exchangeInput)
        .finally(() => {
            setExchangeInput('')
            refreshMarketplaces()
        })
    }

    const onClickRankingOnOffBtn = exchange => {
        let promise
        if (exchange.shouldRank) {
            promise = postMarketplaceRankingDisable(token, exchange._id)
        } else {
            promise = postMarketplaceRankingEnable(token, exchange.name)
        }
        promise.finally(() => {
            refreshMarketplaces()
        })
    }

    const onClickRemoveBtn = exchange => {
        removeMarketplace(exchange._id)
        .finally(() => {
            refreshMarketplaces()
        })
    }
    
    return (
        <div className='rankings-settings'>
            <div className='marketplaces-settings'>
                <h3>거래소 추가</h3>
                <div className='add-exchange'>
                    <div className='input-wrapper'>
                        <input value={exchangeInput}
                            onChange={e => setExchangeInput(e.target.value)}
                            placeholder='거래소 이름'
                        />
                    </div>
                    <div className='button-wrapper'>
                        <button onClick={onClickAddExchange}>추가</button>
                    </div>
                </div>
                <div className='exchange-list'>
                    <h4>등록된 거래소</h4>
                    {marketplaces.map((ex, i) =>
                        <div key={i} className='exchange-list-item'>
                            <div className='left'>
                                <div className='light' style={ex.shouldRank ? {backgroundColor: 'green'} : {backgroundColor: '#ccc'}} />
                                <div className='name' style={!ex.shouldRank ? {color: '#999'} : {}}>
                                    {ex.name}
                                </div>
                            </div>
                            <div className='right'>
                                <div className='btn-wrapper'>
                                    <button style={ex.shouldRank ? {backgroundColor: '#ff5555'} : {backgroundColor: 'blue'}}
                                        onClick={() => onClickRankingOnOffBtn(ex)}
                                    >
                                        {ex.shouldRank ? '랭킹 끄기' : '랭킹 켜기'}
                                    </button>
                                </div>
                                <div className='btn-wrapper'>
                                    <button className='text-black' onClick={() => onClickRemoveBtn(ex)}>
                                        제거
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
