import { useState } from 'react'
import './styles.css'
import SearchIcon from '@mui/icons-material/Search';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const MemberStoreList = ({
    title,
    items,
    onClickVerify,
    onClickHold,
    disableVerify,
    disableHold,
    matchWithKeyword
}) => {
    const [isFold, fold] = useState(true)
    const [searchKeyword, setSearchKeyword] = useState('')

    const itemsToShow = items.filter(el => matchWithKeyword(searchKeyword, el))
    
    return (
        <div className='memberstore-list'>
            <div className='head-row'>
                <div className='title'>
                    {title} ({items.length})
                </div>
                <div className='search'>
                    <input type='text'
                        value={searchKeyword}
                        onChange={e => setSearchKeyword(e.target.value)}
                    />
                    <SearchIcon />
                </div>
                <div className='fold-btn' onClick={() => fold(!isFold)}>
                    {isFold ?
                    <ArrowRightIcon />
                    :
                    <ArrowDropDownIcon />
                    }
                </div>
            </div>
            
            {!isFold &&
                <div className='list-items-wrapper'>
                    {itemsToShow.map(el =>
                        <MemberStoreListCell item={el} key={el._id} id={el._id}
                            onClickVerify={() => onClickVerify(el)}
                            onClickHold={(comment) => onClickHold(el, comment)}
                            disableVerify={disableVerify}
                            disableHold={disableHold}
                        />
                    )}
                </div>
            }
        </div>
    )
}

const MemberStoreListCell = ({
    item,
    id,
    onClickVerify,
    onClickHold,
    disableVerify,
    disableHold,
}) => {
    const [commentInput, setCommentInput] = useState('')
    
    return (
        <div className='list-item' key={id}>
            {item.isUpdateRequest ?
                <div className='tag tag-update'>
                    수정
                </div>
                :
                <div className='tag tag-new'>
                    신규
                </div>
            }
            <div className='name'>
                {item.name}
            </div>
            <div className='btn-wrapper'>
                <button className='btn-verify' onClick={onClickVerify} disabled={!!disableVerify}>
                    등록
                </button>
            </div>
            <div className='hold-section'>
                {!disableHold ?
                    <div className='comment-input-wrapper'>
                        <input type='text'
                            value={commentInput}
                            onChange={e => setCommentInput(e.target.value)}
                            placeholder='보류 사유'
                        />
                    </div>
                    :
                    <div className='comment-input-wrapper'>
                        <input type='text'
                            value={item.adminComment}
                            disabled={true}
                            placeholder='보류 사유'
                        />
                    </div>
                }
                <div className='btn-wrapper'>
                    <button className='btn-hold' onClick={() => onClickHold(commentInput)} disabled={!!disableHold}>
                        보류
                    </button>
                </div>
            </div>
        </div>
    )
}

