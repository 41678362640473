import axios from 'axios'

export const createCarouselContent = (token, params) => {
    return axios.post(`${process.env.REACT_APP_API_SERVER}/admin/carousel/new`, params, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const updateCarouselContent = (token, id, params) => {
    return axios.put(`${process.env.REACT_APP_API_SERVER}/admin/carousel/edit`, {id, params}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchCarouselContent = (token, id) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/carousel/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}

export const fetchCarouselContents = (token) => {
    return axios.get(`${process.env.REACT_APP_API_SERVER}/admin/carousel/all`, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
}
