import { useCollectionNfts } from "../../atoms/collection"

export const CollectionNftList = () => {
    const nfts = useCollectionNfts()

    return (
        <div className='collection-nft-list-wrapper'>
            <ul>
                <li>NFT 갯수: {nfts.length === 100 ? '100개 이상' : `${nfts.length}개`}</li>
            </ul>
            <div>
                <h3>샘플:</h3>
            </div>
            <div className='collection-nft-list'>
                {nfts.slice(0, 8).map((item, i) =>
                    <CollectionNftListItem key={i} nft={item} />
                )}
            </div>
        </div>
    )
}

const CollectionNftListItem = ({nft}) => {
    return (
        <div className='nft-list-item'>
            <img src={getNftImageUrl(nft)} />
        </div>
    )
}

export const getNftImageProp = nft => nft?.metadata?.image || nft?.metadata?.image_url || nft?.metadata?.animation

export const getNftImageUrl = (nft) => {
    let image = getNftImageProp(nft)
    if (!image) return null

    if (image.indexOf('https://ipfs.io/ipfs/') == 0) {
        return `https://ipfs.bithomp.com/image/${image.split('https://ipfs.io/ipfs/')[1]}`
    } else if (image.indexOf('ipfs://ipfs/') == 0) {
        return `https://ipfs.bithomp.com/image/${image.split('ipfs://ipfs/')[1]}`
    } else if (image.indexOf('ipfs://') == 0) {
        return `https://ipfs.bithomp.com/image/${image.split('ipfs://')[1]}`
    } else if (image.indexOf('http') == 0) {
        return image
    } else {
        return `https://ipfs.bithomp.com/image/${image}`
    }
}
