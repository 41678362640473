import {useState, useEffect} from 'react'
import { requestEmailAuth, signIn } from '../../queries/auth'
import { useAdminEmail, useAdminTokenUpdate } from '../../atoms/auth'
import './SignIn.css'

export const SignIn = () => {
    const [email, setEmail] = useState('')
    const [passcode, setPasscode] = useState('')
    const [error, setError] = useState(null)
    const [isWaiting, setIsWaiting] = useState(false)
    const [showPasscodeInput, setShowPasscodeInput] = useState(false)
    const setToken = useAdminTokenUpdate()
    const [_adminEmail, setAdminEmail] = useAdminEmail()

    useEffect(() => {
        if (passcode.length === 6) {
            onClickSignIn()
        }
    }, [passcode])

    const validateEmail = (email) => {
        var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return re.test(email)
    }

    const onClickEmailAuth = () => {
        setIsWaiting(true)
        requestEmailAuth(email)
            .then((result) => {
                setIsWaiting(false)
                if (result.success === true) {
                    setError(null)
                    setShowPasscodeInput(true)
                } else {
                    console.error(result)
                }
            })
            .catch(e => {
                setIsWaiting(false)
                if (e?.response?.status === 404) {
                    setError('관리자 메일주소가 아닙니다.')
                } else {
                    setError('서버 오류')
                }
            })
    }

    const onClickSignIn = () => {
        signIn(email, passcode)
            .then((data) => {
                setError(null)
                setAdminEmail(data.email)
                setToken(data.token)
            })
            .catch(e => setError(e?.error || e))
    }

    return (
        <div className='signin-wrapper'>
            <div className='signin-box'>
                {error &&
                    <div className='error-box'>
                        {error}
                    </div>
                }
                <div className='email-input-wrapper'>
                    <input type='text'
                        placeholder='Email Address'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                {showPasscodeInput &&
                    <div className='passcode-input-wrapper'>
                        <input type='text'
                            placeholder='Validation Code'
                            value={passcode}
                            onChange={e => setPasscode(e.target.value)}
                        />
                    </div>
                }
                <div className='buttons'>
                    <div className='button-wrapper'>
                        <button disabled={!validateEmail(email) || isWaiting}
                            onClick={onClickEmailAuth}
                        >{showPasscodeInput ? '메일 재인증' : '메일 인증'}</button>
                    </div>
                    {showPasscodeInput &&
                        <div className='button-wrapper'>
                            <button disabled={!passcode}
                                onClick={onClickSignIn}>로그인</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
